const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
const formatMessage = require('format-message');
const Locales = ['ja', 'ja-Hira', 'en'];

const Message = {
    whenGamepadButtonPressed: {
        'en': 'when Gamepad [BUTTON] button pressed',
        'ja': 'ゲームパッドのボタン [BUTTON] が押された時',
        'ja-Hira': 'ゲームパッドのボタン [BUTTON] がおされたとき',
    },
    whenGamepadButtonReleased: {
        'en': 'when Gamepad [BUTTON] button released',
        'ja': 'ゲームパッドのボタン [BUTTON] が離された時',
        'ja-Hira': 'ゲームパッドのボタン [BUTTON] がはなされたとき',
    },
    whenGamepadAxisChanged: {
        'en': 'when Gamepad [AXIS] changed',
        'ja': 'ゲームパッドの [AXIS] が変化したとき',
        'ja-Hira': 'ゲームパッドの [AXIS] がかわったとき',
    },
    valueJoystickX: {
        'en':'joystick Left X value',
        'ja':'左スティックXの値',
        'ja-Hira':'ひだりスティックXのあたい',
    },
    valueJoystickY: {
        'en':'joystick Left Y value',
        'ja':'右スティックYの値',
        'ja-Hira':'みぎスティックYのあたい',
    },

};

class Scratch3GamepadExtension {

    constructor(runtime) {
        this.runtime = runtime;
        this.locale = this._getViewerLanguageCode();
        this.gamepadButtonA_pressed_before = false;
        this.gamepadButtonB_pressed_before = false;
        this.gamepadButtonX_pressed_before = false;
        this.gamepadButtonY_pressed_before = false;

        this.joystickX_before = 0;
        this.joystickY_before = 0;

        this.joystickX = 0;
        this.joystickY = 0;

        setInterval(() => this.updateGamepadValues(), 80); // 毎秒10回ゲームパッドの値を更新
    }

    _getViewerLanguageCode() {
        const locale = formatMessage.setup().locale;
        if (Locales.includes(locale)) {
            return locale;
        }
        return 'en';
    }

    getInfo() {
        this.locale = this._getViewerLanguageCode();
        return {
            id: 'gamepad',
            name: 'Gamepad',
            color1: '#36C2CE',  // 主色
            color2: '#36C2CE',  // 二次色
            color3: '#191970',  // 三次色
            // blockIconURI: blockIconURI, // アイコン変えたければこれ
            blocks: [
                {
                    opcode: 'whenGamepadButtonPressed',
                    blockType: BlockType.HAT,
                    text: Message.whenGamepadButtonPressed[this.locale],
                    arguments: {
                        BUTTON: {
                            type: ArgumentType.STRING,
                            menu: 'gamepadButtons',
                            defaultValue: 'A'
                        }
                    }
                },
                {
                    opcode: 'whenGamepadButtonReleased',
                    blockType: BlockType.HAT,
                    text: Message.whenGamepadButtonReleased[this.locale],
                    arguments: {
                        BUTTON: {
                            type: ArgumentType.STRING,
                            menu: 'gamepadButtons',
                            defaultValue: 'A'
                        }
                    }
                },

                {
                    opcode: 'whenGamepadAxisChanged',
                    blockType: BlockType.HAT,
                    text: Message.whenGamepadAxisChanged[this.locale],
                    arguments: {
                        AXIS: {
                            type: ArgumentType.STRING,
                            menu: 'gamepadAxis',
                            defaultValue: 'X'
                        }
                    }
                },
                {
                    opcode: 'getJoystickX',
                    blockType: BlockType.REPORTER,
                    text: Message.valueJoystickX[this.locale],
                },
                {
                    opcode: 'getJoystickY',
                    blockType: BlockType.REPORTER,
                    text: Message.valueJoystickY[this.locale],
                },

            ],
            menus: {
                gamepadAxis: {
                    acceptReporters: true,
                    items: ['X', 'Y', 'ANY']
                },
                gamepadButtons: {
                    acceptReporters: true,
                    items: ['A', 'B', 'X', 'Y']
                }
            }
        };
    }

    updateGamepadValues() {
        const gamepads = navigator.getGamepads();
        if (gamepads[0]) {
            this.joystickX = Math.round(gamepads[0].axes[0]*100);
            this.joystickY = Math.round(gamepads[0].axes[1]*100);
        }
    }

    getJoystickX() {
        return this.joystickX;
    }

    getJoystickY() {
        return this.joystickY;
    }

    whenGamepadButtonPressed(args) {
        const gamepads = navigator.getGamepads();
        if (gamepads[0]) {
            if (args.BUTTON === 'A') {
                const current = gamepads[0].buttons[0].pressed;
                if (!this.gamepadButtonA_pressed_before && current) {
                    this.gamepadButtonA_pressed_before = current;
                    return true;
                }
                this.gamepadButtonA_pressed_before = current;
            } else if (args.BUTTON === 'B') {
                const current = gamepads[0].buttons[1].pressed;
                if (!this.gamepadButtonB_pressed_before && current) {
                    this.gamepadButtonB_pressed_before = current;
                    return true;
                }
                this.gamepadButtonB_pressed_before = current;
            } else if (args.BUTTON === 'X') {
                const current = gamepads[0].buttons[2].pressed;
                if (!this.gamepadButtonX_pressed_before && current) {
                    this.gamepadButtonX_pressed_before = current;
                    return true;
                }
                this.gamepadButtonX_pressed_before = current;
            } else if (args.BUTTON === 'Y') {
                const current = gamepads[0].buttons[3].pressed;
                if (!this.gamepadButtonY_pressed_before && current) {
                    this.gamepadButtonY_pressed_before = current;
                    return true;
                }
                this.gamepadButtonY_pressed_before = current;
            }
        }
        return false;
    }

    whenGamepadAxisChanged(args) {
        const gamepads = navigator.getGamepads();
        if (gamepads[0]) {
            if (args.AXIS === 'Y') {
                const currentY = Math.round(gamepads[0].axes[1]);
                if (this.joystickY_before != currentY) {
                    this.joystickY_before = currentY;
                    return true;
                }
                this.joystickY_before = currentY;
            } else if (args.AXIS === 'X') {
                const currentX = Math.round(gamepads[0].axes[0]);
                if (this.joystickX_before != currentX) {
                    this.joystickX_before = currentX;
                    return true;
                }
                this.joystickX_before = currentX;
            } else if (args.AXIS === 'ANY') {
                const currentX = Math.round(gamepads[0].axes[0]);
                const currentY = Math.round(gamepads[0].axes[1]);
                if (this.joystickY_before != currentY || 
                    this.joystickX_before != currentX) {
                    this.joystickX_before = currentX;
                    this.joystickY_before = currentY;
                    return true;
                }
                this.joystickX_before = currentX;
                this.joystickY_before = currentY;
            }
        }
        return false;
    }
}

module.exports = Scratch3GamepadExtension;
