import { gapi } from 'gapi-script';

// 認証トークンを保存するための変数
let oauthToken = null;

// Google API クライアントの初期化とサインイン処理
export async function initializeGapiAndSignIn() {
    return new Promise((resolve, reject) => {
        gapi.load('client:auth2', () => {
            gapi.auth2.init({
                client_id: '1056071278981-ip25c6s7emsnvrvcr43hbs82isllh7ec.apps.googleusercontent.com',
                scope: 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive'
            }).then(() => {
                const authInstance = gapi.auth2.getAuthInstance();

                // 認証が必要な場合、再認証
                function signInAndRetrieveToken() {
                    authInstance.signIn({
                        scope: 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive'
                    }).then(() => {
                        oauthToken = authInstance.currentUser.get().getAuthResponse().access_token;
                        resolve(oauthToken);
                    }).catch(error => {
                        reject('Error signing in: ' + error);
                    });
                }

                if (!authInstance.isSignedIn.get()) {
                    signInAndRetrieveToken();
                } else {
                    // サインイン済みの場合もトークンの確認と再取得
                    const user = authInstance.currentUser.get();
                    if (!user.hasGrantedScopes('https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive')) {
                        signInAndRetrieveToken();
                    } else {
                        oauthToken = user.getAuthResponse().access_token;
                        resolve(oauthToken);
                    }
                }
            }).catch(error => {
                reject('Error initializing GAPI: ' + error);
            });
        });
    });
}

// Google Drive へのファイルアップロード関数
function uploadToGoogleDrive(blob, fileName) {
    initializeGapiAndSignIn().then(oauthToken => {
        const metadata = {
            'name': fileName,
            'mimeType': blob.type
        };

        const form = new FormData();
        form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
        form.append('file', blob);

        fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
            method: 'POST',
            headers: new Headers({ 'Authorization': 'Bearer ' + oauthToken }),
            body: form
        }).then((response) => {
            if (!response.ok) {
                return response.json().then(err => {
                    console.error('Server returned an error:', err);
                    const errorMessage = err.message || JSON.stringify(err) || 'Unknown error';
                    throw new Error('Error uploading file: ' + errorMessage);
                });
            }
            return response.json();
        }).then((value) => {
            console.log('File uploaded successfully:', value);
            alert('ファイルがGoogle Driveにアップロードされました');
        }).catch((error) => {
            console.error('Upload failed:', error);
            alert('ファイルのアップロード中にエラーが発生しました: ' + error.message);
        });
    }).catch(error => {
        console.error('Google API initialization or sign-in failed:', error);
        alert('Google API の初期化またはサインイン中にエラーが発生しました: ' + error.message);
    });
}

export default uploadToGoogleDrive;
